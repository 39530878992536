import { Col, Divider, Row, Typography } from 'antd';
import { Image } from 'antd';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { SiteContent } from '../services/requests';

const { Text } = Typography;

function FieldName(props: { name: string }) {
  return <Col span={8}>{props.name}</Col>;
}

function InfoField(props: {
  name: string;
  children: ReactNode;
  show: boolean;
}) {
  if (!props.show) {
    return <></>;
  }
  return (
    <>
      <Row>
        <FieldName name={props.name} />
        <Col span={16}>{props.children}</Col>
      </Row>
      <Divider />
    </>
  );
}

export default function SiteInfo(props: { siteContent: SiteContent }) {
  const { siteContent } = props;
  const { t } = useTranslation();
  return (
    <Col span={24}>
      <InfoField name={t('form.name.label')} show>
        <Text style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
          {siteContent.name}
        </Text>
      </InfoField>
      <InfoField name={t('form.address.label')} show>
        <Text style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
          {siteContent.address_text}
        </Text>
      </InfoField>
      <InfoField name={t('form.hours.label')} show>
        <Text style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
          {siteContent.hours}
        </Text>
      </InfoField>
      <InfoField name={t('form.phone.label')} show={!!siteContent.phone}>
        <Text style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
          {siteContent.phone}
        </Text>
      </InfoField>
      <InfoField name={t('form.facebook.label')} show={!!siteContent.facebook}>
        <Text style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
          {siteContent.facebook}
        </Text>
      </InfoField>
      <InfoField
        name={t('form.instagram.label')}
        show={!!siteContent.instagram}
      >
        <Text style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
          {siteContent.instagram}
        </Text>
      </InfoField>
      <InfoField
        name={t('form.googleMaps.label')}
        show={!!siteContent.google_maps}
      >
        <Text style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
          {siteContent.google_maps}
        </Text>{' '}
      </InfoField>
      <InfoField
        name={t('form.tripadvisor.label')}
        show={!!siteContent.tripadvisor}
      >
        <Text style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
          {siteContent.tripadvisor}
        </Text>{' '}
      </InfoField>
      <InfoField name={t('form.about.label')} show={!!siteContent.about}>
        <Text style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
          {siteContent.about}
        </Text>
      </InfoField>
      <InfoField name={t('form.logo.label')} show={!!siteContent.logo_url}>
        <Image width={50} src={siteContent.logo_url} />
      </InfoField>
      <InfoField
        name={t('form.mainImage.label')}
        show={!!siteContent.main_image_url}
      >
        <Image width={50} height={50} src={siteContent.main_image_url} />
      </InfoField>
      <InfoField
        name={t('form.images.label')}
        show={!!siteContent.image_urls && siteContent.image_urls.length > 0}
      >
        <Image.PreviewGroup>
          {siteContent.image_urls &&
            siteContent.image_urls.map((url, i) => (
              <Image width={50} height={50} src={url} key={i} />
            ))}
        </Image.PreviewGroup>
      </InfoField>
      <InfoField
        name={t('form.menu.label')}
        show={!!siteContent.menu_urls && siteContent.menu_urls.length > 0}
      >
        <Image.PreviewGroup>
          {siteContent.menu_urls &&
            siteContent.menu_urls.map((url, i) => (
              <Image width={50} height={50} src={url} key={i} />
            ))}
        </Image.PreviewGroup>
      </InfoField>
    </Col>
  );
}
