import { notification } from 'antd';
import { useState } from 'react';

import { SERVER_URL } from '../../services/common';
import { useTranslation } from 'react-i18next';

interface IValues {
  name: string;
  email: string;
  message: string;
}

const initialValues: IValues = {
  name: '',
  email: '',
  message: '',
};

export const useForm = (validate: { (values: IValues): IValues }) => {
  const { t } = useTranslation();
  const [formState, setFormState] = useState<{
    values: IValues;
    errors: IValues;
  }>({
    values: { ...initialValues },
    errors: { ...initialValues },
  });

  const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    const values = formState.values;
    const errors = validate(values);
    setFormState((prevState) => ({ ...prevState, errors }));

    const url = SERVER_URL + '/api/v1/contact'; // Fill in your API URL here

    try {
      if (Object.values(errors).every((error) => error === '')) {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(values),
        });

        if (!response.ok) {
          notification['error']({
            message: 'Error',
            description: t('contactUs.error'),
          });
        } else {
          event.target.reset();
          setFormState(() => ({
            values: { ...initialValues },
            errors: { ...initialValues },
          }));

          notification['success']({
            message: 'Success',
            description: t('contactUs.success'),
          });
        }
      }
    } catch (error) {
      notification['error']({
        message: 'Error',
        description: t('contactUs.error'),
      });
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    event.persist();
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: '',
      },
    }));
  };

  return {
    handleChange,
    handleSubmit,
    values: formState.values,
    errors: formState.errors,
  };
};
