import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';

import queryClient from './common/queryClient';
import Router from './router';
import i18n from './translation';
import ErrorBoundary from './common/ErrorBoundary';
import logger from './services/logger';

const App = () => (
  <ErrorBoundary>
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <QueryClientProvider client={queryClient}>
          <Router />
        </QueryClientProvider>
      </I18nextProvider>
    </BrowserRouter>
  </ErrorBoundary>
);
const container = document.getElementById('root');
if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID)
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
else logger.warning('Google Analytics ID is empty');

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(<App />);

window.onerror = function (message, source, lineno, colno, error) {
  logger.error(
    `Global error caught: ${message} at ${source}:${lineno}:${colno}`,
  );
  return true;
};

window.onunhandledrejection = function (event) {
  logger.error(`Unhandled promise rejection: ${event.reason}`);
  return true;
};
